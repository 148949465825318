import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="forward-click"
export default class extends Controller {
  static targets = ["origin"];

  forward(e) {
    if (e.target !== this.originTarget) {
      this.originTarget.click();
      e.preventDefault();
    }
  }
}
